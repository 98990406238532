<template>
    <div class="custom-container d-flex flex-column">
        <AppHeader />

        <!-- Description Section -->
        <!-- Main Content Section -->
        <main class="container-fluid px-0">
            <div class="row width940 mx-auto mt-5">
                <div class="col-12">
                    <section class="py-5">
                        <h2 class="lexi-h2 my-5" lang="fr">Explorez par domaine</h2>
                        <ul class="row g-3 list-unstyled">
                            <li v-for="domain in domains" :key="domain.title" class="col-12 col-md-6">
                                <div class="card h-100 shadow-sm hover-overlay text-decoration-none"
                                     @click="navigateToDomain(domain.title)"
                                     role="link">
                                    <div class="card-body d-flex align-items-start">
                                        <div class="d-flex align-items-center justify-content-center me-4">
                                            <img :src="domain.image" :alt="`${domain.title} Icon`" class="img-fluid" style="max-width: 48px;" />
                                        </div>
                                        <div class="flex-grow-1">
                                            <h3 class="lexi-h3 mb-1" lang="fr">{{ domain.title }}</h3>
                                            <p class="lexi-subtitle text-muted mb-2" lang="fr">{{ domain.subtitle }}</p>
                                            <small class="text-secondary" lang="fr">{{ domain.termsCount }}+ termes</small>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
            <div class="lexi-stats bg-light">
                <div class="container width940">
                    <div class="row justify-content-center">
                        <div class="col-12 py-5">
                            <section class="lexi-stats__section pb-4">
                                <h2 class="lexi-stats__heading lexi-h2 my-5" lang="fr">À propos</h2>
                                <p class="lexi-stats__description width768" lang="fr">
                                    LexiConcordia est une base de données novatrice regroupant 4 500 termes spécialisés et originaux, conçue pour accompagner les étudiant·es, enseignant·es et professionnel·les dans leur apprentissage du français technique et scientifique.
                                </p>
                                <p class="text-xlarge width768" lang="fr">
                                    Enrichie de traductions spécialisées, de contextes pratiques et d'exercices interactifs, elle soutient activement l'acquisition de compétences linguistiques dans des domaines technico-scientifiques variés.
                                </p>
                                <div class="lexi-stats__grid row mt-3 g-4">
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <div class="lexi-stats__item">
                                            <p class="lexi-stats__item-title mb-2" lang="fr">4500+ termes</p>
                                            <p class="lexi-stats__item-description text-medium" lang="fr">Vocabulaire technique validé par des expert·es du domaine.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <div class="lexi-stats__item">
                                            <p class="lexi-stats__item-title mb-2" lang="fr">7 Domaines</p>
                                            <p class="lexi-stats__item-description text-medium" lang="fr">De l'aérospatial aux sciences cognitives, couvrant les disciplines techniques majeures.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <div class="lexi-stats__item">
                                            <p class="lexi-stats__item-title mb-2" lang="fr">30+ exercices</p>
                                            <p class="lexi-stats__item-description text-medium" lang="fr">Activités interactives pour maîtriser le vocabulaire technique.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <div class="lexi-stats__item">
                                            <p class="lexi-stats__item-title mb-2" lang="fr">75+ Collaborateurs</p>
                                            <p class="lexi-stats__item-description text-medium" lang="fr">Un réseau de spécialistes du domaine.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <AppFooter />
    </div>
</template>


<script>
    import AppHeader from './AppHeader.vue';
    // import AppFooter from './AppFooter.vue';
    import { useDataService } from '../dataService.ts'; // Import your data service here

    export default {
        name: 'HomePage',
        data() {
            return {
                domains: [
                    { title: 'biologie', subtitle: 'biology', termsCount: '0', image: require('./../assets/Biology.svg') },
                    { title: 'génie aérospatial', subtitle: 'aerospace engineering', termsCount: '0', image: require('./../assets/Aerospace.svg') },
                    { title: 'génie environnemental', subtitle: 'environmental engineering', termsCount: '0', image: require('./../assets/Environmental.svg') },
                    { title: 'génie des systèmes', subtitle: 'systems engineering', termsCount: '0', image: require('./../assets/Systems.svg') },
                    { title: 'gestion', subtitle: 'management', termsCount: '0', image: require('./../assets/Management.svg') },
                    { title: 'informatique', subtitle: 'computer science', termsCount: '0', image: require('./../assets/Computer.svg') },
                    { title: 'sciences cognitives', subtitle: 'neuroscience', termsCount: '0', image: require('./../assets/linkIcon.svg') }
                ]
            };
        },
        mounted() {
            this.updateTermsCounts(); // Fetch term counts when the component mounts
        },
        methods: {
            async updateTermsCounts() {
                const dataService = useDataService();

                for (const domain of this.domains) {
                    try {
                        const response = await dataService.getRowsForDomaine(domain.title);
                        domain.termsCount = response.length; // Update with the number of terms
                    } catch (error) {
                        console.error(`Error fetching terms count for ${domain.title}:`, error);
                        domain.termsCount = '...'; // Display fallback text if error occurs
                    }
                }
            },
            navigateToDomain(title) {
                const normalizedTitle = title
                    .normalize('NFD') // Normalize to separate accents from letters
                    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
                    .toLowerCase()
                    .replace(/ /g, '-'); // Replace spaces with hyphens

                this.$router.push({ path: `/${normalizedTitle}` });
            },
        },
        components: {
            AppHeader,
            // AppFooter,
        },
    };
</script>

<style scoped>

    .lexi-stats h2 {
    }

    .lexi-stats {
    }

    .lexi-stats__item {
        padding-top: 1rem;
        border-top: 2px solid #912338;
    }

    .lexi-stats__item-title {
        font-size: 1.5rem !important;
        font-family: "Gill Sans MT Pro Bold Condensed" !important;
    }

    .lexi-stats__item-description {
        font-size: 1.25rem !important;
        line-height: 1.3 !important;
        font-family: "Gill Sans MT Pro Book" !important;
    }

    .lexi-stats__description {
        font-size: 28px !important;
        font-family: "Gill Sans MT Pro Book" !important;
        text-wrap: balance;
        line-height: 1.3 !important;
    }


    .lexi-h2 {
        font-size: 40px !important;
        font-family: "Gill Sans MT Pro Bold Condensed" !important;
        letter-spacing: -0.01em !important;
    }

    .lexi-h3 {
        font-size: 24px !important;
        font-family: "Gill Sans MT Pro Bold Condensed" !important;
        letter-spacing: -0.01em !important;
    }

    .lexi-subtitle {
        font-size: 20px !important;
        font-family: "Gill Sans MT Pro Book" !important;
        margin-top: 0.5rem !important;
    }

    .custom-container {
        width: 100%;
        overflow-x: hidden;
        min-height: 100vh;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .main-container {
        max-width: 940px;
        padding: 60px 16px;
    }

    .description {
        max-width: 940px; /* Ensuring description section matches the main container */
    }

    .card-body {
        background: white;
        border-radius: 4px;
        padding: 16px;
        transition: background-color 0.2s;
    }

    .card:hover .card-body {
        background-color: #F0F0F080;
    }

    .card-title {
        font-family: "Gill Sans MT Pro Bold Condensed";
        font-size: 22px;
        font-weight: 700;
        text-align: left;
        margin: 0;
    }

    .card-subtitle {
        font-family: "Gill Sans MT Pro";
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        margin: 0;
    }

    .card-terms {
        font-family: Arial, sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin: 4px 0 0 0;
    }

    .card-image-div {
        width: 50px;
        height: 50px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        overflow: hidden;
    }

    .card-image {
        width: 30px;
        height: 30px;
    }



    .lexi-main-heading {
        font-family: "Gill Sans MT Pro Bold Condensed";
        font-size: 40px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 1rem;
    }

    @media (max-width: 992px) {
        .main-container {
            max-width: 780px;
            padding: 40px 16px;
        }

        .description {
            max-width: 780px;
        }

        .description-text {
            font-size: 28px;
        }

        .card-body {
            padding: 16px;
        }

        .card-image-div {
            width: 40px;
            height: 40px;
        }

        .card-image {
            width: 24px;
            height: 24px;
        }
    }

    @media (max-width: 768px) {
        .main-container {
            padding: 0 16px;
        }

        .lexi-main-heading {
            padding-top: 32px;
        }

        .description {
            max-width: 100%; /* Ensure responsiveness on smaller screens */
            padding: 0 16px;
        }

        .description-text {
            font-size: 28px;
        }

        .card-body {
            padding: 16px;
        }

        .card-image-div {
            width: 48px;
            height: 48px;
        }

        .card-image {
            width: 30px;
        }
    }
</style>
