<template>
    <footer class="footer text-white">
      <div class="row justify-content-center top-footer-div">
        <div class="footer-width">
          <div class="row">
            <div class="col-12 col-md-4">
              <img src="@/assets/fotorLogo.svg" alt="LEXICONCORDIA" class="footer-logo">
            </div>
          </div>
        </div>
      </div>
  
      <div class="row justify-content-center footer-links middle-footer-div">
        <div class="footer-width">
          <div class="row">
            <div class="col-12 col-md-4">
              <h5 class="footer-heading">List 1</h5>
              <ul class="list-unstyled">
                <li><router-link to="" class="footer-link" lang="fr">Undergraduate</router-link></li>
                <li><router-link to="" class="footer-link" lang="fr">Graduate</router-link></li>
                <li><router-link to="" class="footer-link" lang="fr">Tuition & financial aid</router-link></li>
              </ul>
            </div>
            <div class="col-12 col-md-4">
              <h5 class="footer-heading">List 2</h5>
              <ul class="list-unstyled">
                <li><router-link to="" class="footer-link" lang="fr">Calendar of events</router-link></li>
                <li><router-link to="" class="footer-link" lang="fr">Latest news</router-link></li>
                <li><router-link to="" class="footer-link" lang="fr">Media Relations</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row justify-content-center bottom-footer-div">
        <div class="d-flex justify-content-center footer-width">
          <div class="bottom-footer-width">
            <div class="row d-flex justify-content-center">
              <div class="col-2">
                <img src="@/assets/college-2.png" alt="Image 1" class="img-fluid">
              </div>
              <div class="col-1"></div>
              <div class="col-4">
                <img src="@/assets/concordia-2.png" alt="Image 2" class="img-fluid">
              </div>
              <div class="col-1"></div>
              <div class="col-4">
                <img src="@/assets/qubec.png" alt="Image 3" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooter'
  }
  </script>
  
  <style scoped>
  
  .top-footer-div {
    padding: 26px 0px 26px 0px;
    gap: 10px;
    border: 0px 0px 1px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: #912338;
  }
  
  .middle-footer-div {
    padding: 52px 16px 52px 16px;
    gap: 10px;
    background-color: #912338;
  }
  
  .bottom-footer-div{
    padding: 26px 0px 26px 0px;
    gap: 10px;
    border: 0px 0px 1px 0px;
    border-bottom: 1px solid #3C3C3C
  }
    
  .footer-width{
    width: 940px;
  }
  
  .bottom-footer-width{
    width: 512px;
    height: 61px
  }
  
  .footer-logo {
    height: 2.5rem;
  }
  
  .footer-links ul {
    padding-left: 0;
  }
  
  .footer-heading {
    font-family: 'Gill Sans MT Pro';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
    color: #FFFFFF;
  }
  
  .footer-link {
    text-decoration: none;
    color: white;
    font-family: 'Gill Sans MT Pro';
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: #FFFFFFB2;  
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  footer img {
    max-height: 9.375rem;
    max-width: 100%;
  }
  
  @media(max-width: 992px) {
    .footer-width{
      width: 768px;
    }
  }
  @media(max-width: 768px) {
    .footer-logo {
      padding-left: 16px;
    }
  }
  </style>
  
