<template>
    <div class="custom-container" id="top">
      <AppHeader />
  
      <main class="main-content">
        <!-- Left Content with Terms -->
        <ol class="terms-container">
          <!-- Header Row with Buttons -->
          <div class="header-row">
            <p class="result-text" lang="fr">Sciences cognitives</p>
            <div class="button-group">
              <button @click="toggleView" class="view-toggle-button">
                <img src="@/assets/fourLines.svg" alt="View Toggle Icon" class="icon" width="20" />
                {{ isCardView ? 'List View' : 'Card View' }}
              </button>
            </div>
          </div>
  
          <!-- Grouped Results by Alphabet -->
          <li v-for="(group, letter) in groupedResults" :key="letter" :id="letter" class="letter-section">
            <h2>{{ letter }}</h2>
            <ol 
              v-for="result in group" 
              :key="result.VEDETTE_FRANÇAISE" 
              class="term-section" 
              @click="navigateToDetails(result)"
              role="link"
            >
              <li class="details-box" :class="{ 'list-view': !isCardView }">
                <div class="row-content">
                  <div class="left-content">
                    <span>{{ result.VEDETTE_FRANÇAISE }}</span>
                    <span class="language-box">{{ isFlipped ? 'EN' : 'FR' }}</span> |
                    <span>{{ result.VEDETTE_ANGLAISE }}</span>
                    <span class="language-box" lang="en">{{ isFlipped ? 'FR' : 'EN' }}</span>
                  </div>
                  <div v-if="!isCardView" class="right-content">
                    <span class="bold">{{ result.DOMAINE }}</span> | {{ result.SOUS_DOMAINE }}
                  </div>
                </div>
  
                <div v-if="isCardView" class="row-2">
                  <br />
                  <span class="bold">{{ result.DOMAINE }}</span> | {{ result.SOUS_DOMAINE }}
                  <br /><br />
                  {{ result.DEFINITION }}
                </div>
              </li>
            </ol>
          </li>
        </ol>
  
        <!-- Alphabet Menu -->
        <nav class="alphabet-menu" aria-label="alphabetic-navigation">
          <p class="menu-label">Sauter:</p>
          <button
            v-for="letter in alphabet"
            :key="letter"
            @click="scrollToSection(letter)"
            :class="{'inactive': !groupedResults[letter]}"
          >
            {{ letter }}
          </button>
        </nav>
  
      </main>
  
      <!-- Tooltip for Copy Notification -->
      <button @click="scrollToSection('top')" class="copy-tooltip">
          Retour en haut de la page
      </button>
  
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppHeader from './AppHeader.vue';
  import AppFooter from './AppFooter.vue';
  import { useDataService } from '../dataService.ts';
  
  export default {
    name: 'CognitivePage',
    components: {
      AppHeader,
      AppFooter,
    },
    data() {
      return {
        results: [], // Store fetched results
        isCardView: true, // Track view state
        isFlipped: false, // Track language state
        alphabet: ['#', ...Array.from(Array(26), (_, i) => String.fromCharCode(65 + i))], // #, A-Z
      };
    },
    computed: {
      groupedResults() {
        const grouped = {};
        this.results.forEach((result) => {
          const term = result.VEDETTE_FRANÇAISE;
  
          // Normalize the term to remove accents and diacritics
          const normalizedTerm = term.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          const firstChar = /^[A-Za-z]/.test(normalizedTerm[0])
            ? normalizedTerm[0].toUpperCase()
            : '#';
  
          if (!grouped[firstChar]) {
            grouped[firstChar] = [];
          }
          grouped[firstChar].push(result);
        });
  
        return grouped;
      }
    },
    mounted() {
      this.fetchSearchResults();
    },
    methods: {
      async fetchSearchResults() {
        const dataService = useDataService();
        try {
          const response = await dataService.getRowsForDomaine('sciences cognitives');
          this.results = response;
        } catch (error) {
          console.error('Error fetching neuroscience terms:', error);
        }
      },
      toggleView() {
        this.isCardView = !this.isCardView;
      },
      toggleLanguage() {
        this.isFlipped = !this.isFlipped;
      },
      navigateToDetails(result) {
        const englishTerm = result.VEDETTE_ANGLAISE;
        this.$router.push({
          path: '/details',
          query: { terme: englishTerm },
        });
      },
      scrollToSection(letter) {
        const section = document.getElementById(letter);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }
  
  .main-content {
    display: flex;
    width: 940px;
    margin: 0 auto; /* Centers the main content */
    padding: 4rem;
  }
  
  .terms-container {
    flex: 1;
  }
  
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .view-toggle-button {
    background-color: white;
    color: black;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .view-toggle-button:hover {
    background-color: #f0f0f0;
  }
  
  .alphabet-menu {
    position: sticky;
    top: 20px; /* Adjust as needed */
    margin-left: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: auto repeat(9, auto); /* 1 row for label, 9 rows for buttons */
    gap: 4px;
    width: 150px; /* Adjust to fit button size */
    height: 300px; /* Fixed height to prevent expansion */
    align-items: start; /* Align items to the top */
  }
  
  .menu-label {
    color: var(--Text-Body-text, #2C2C2C);
    font-family: "Gill Sans MT Pro";
    font-size: var(--Productive-H3-Label, 20px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    grid-column: span 3; /* Span across all columns */
  }
  
  .alphabet-menu button {
    width: 40px;
    height: 40px; /* Adjust size if needed */
    font-size: 14px;
    font-weight: bold;
    color: black;
    background-color: rgba(240, 240, 240, 1.0);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .alphabet-menu button.inactive {
    background-color: white;
    color: gray;
    cursor: default;
  }
  
  .alphabet-menu button:not(.inactive):hover {
    background-color: #C8C8C8;
  }
  
  .letter-section {
    margin-bottom: 30px;
  }
  
  .term-section {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    gap: 16px;
    border-top: 1px solid #C8C8C8;
    transition: background-color 0.2s ease;
  }
  
  .term-section:hover {
    background: rgba(240, 240, 240, 0.6);
  }
  
  .details-box {
    padding: 10px;
  }
  
  .result-text {
    font-family: 'Gill Sans MT Pro Bold Condensed', sans-serif;
    font-size: 4rem;
  }
  
  .details-box.list-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .row-content {
    display: grid;
    justify-content: space-between;
    width: 100%;
  }
  
  .left-content {
    flex: 1;
    font-family: 'Gill Sans MT Pro', sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
  
  .right-content {
    flex: 1;
    text-align: left;
  }
  
  .row-2 {
    font-family: 'Gill Sans MT Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
  
  .language-box {
    width: 34px;
    height: 23px;
    padding: 4px 8px;
    margin-left: 5px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
  }
  
  li {
      list-style-type: none; /* Removes numbering from list items */
      margin-left: 0; /* Optional: aligns list items to the left */
      padding-left: 0; /* Optional: removes padding */
  }
  
  .copy-tooltip {
          position: fixed;
          bottom: 70px;
          right: 20px;
          padding: 8px 16px;
          background-color: #333;
          color: #fff;
          border-radius: 4px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          z-index: 1000;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      }
  
  @media (max-width: 992px) {
    .main-content {
      flex-direction: column;
      align-items: center;
    }
  }
  </style>
  
