<template>
    <div class="index-page">
        <header>
            <h1>LEXI CONCORDIA</h1>
            <div class="search-container">
                <div class="search-bar">
                    <i class="material-icons search-icon"> &#xe8b6; </i>
                    <input v-model="searchTerm" @input="onSearchInputChange" type="text" class="search-input"
                           placeholder="Rechercher un terme...">
                </div>
                <div class="horizontal-space"></div>
                <router-link to="/" class="index-btn">Accueil</router-link>
            </div>
        </header>

        <h2 class="index-title">INDEX</h2>
        <div class="index-button alphabet-navigation">
            <span v-for="letter in alphabet" :key="letter" @click="scrollToSection(letter)" class="alphabet-letter">
                <b>
                    {{
        letter
                    }}
                </b>
            </span>
        </div>

        <div class="content-home">
            <div id="search-results">
                <div class="side-by-side-list">
                    <ul class="side-by-side-container-list">
                        <li v-for="(section, index) in filteredData" :key="'section-' + index">
                            <a :id="'section-' + section.letter"></a>
                            {{ section.letter }}
                            <div class="side-by-side-container" v-for="(item, i) in section.items" :key="'item-' + i">
                                <span class="left-item" @click="navigateToDetails(item['VEDETTE_FRANÇAISE'])">
                                    {{
                  item["VEDETTE_FRANÇAISE"]
                                    }}
                                </span>
                                <span class="right-item">{{ item["VEDETTE_ANGLAISE"] }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="centered-button" v-if="dataLoaded && data && data.length > 0">
                    <router-link to="/" class="index-btn">Accueil</router-link>
                </div>
            </div>
        </div>

        <button v-if="showScrollButton" class="scroll-to-top-button" @click="scrollToTop()">Top</button>
    </div>
</template>

<script>
    const axios = require('axios');
    const { ref, onMounted, onUnmounted, computed } = require('vue');
    const { useRouter } = require('vue-router');
    const { useDataService } = require('../dataService.ts');


    export default {
        name: 'IndexPage',
        setup() {
            const data2 = ref(null);
            const data1 = ref(false);
            const ifdata2 = ref(false);
            const searchTerm = ref('');
            const terms = ref([]);
            const dataLoaded = ref(false);
            const showScrollButton = ref(false);
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
            const router = useRouter();
            const dataService = useDataService();

            const fetchTerms = async () => {
                try {
                    console.log('Fetching terms from the API...');

                    // Make the API call
                    const response = await axios.get('/lexicApi/terms');

                    // Log the entire response to see the structure of the data
                    console.log('API response:', response);
                    console.log('API response data:', response.data);

                    terms.value = response.data;

                    // Check if the data is an array before using .map()
                    // if (Array.isArray(response.data)) {
                    //   // Process and store the data if it's an array
                    //   terms.value = response.data.map(course => ({
                    //     id: course.ID,
                    //     title: course.title,
                    //     subject: course.subject,
                    //     catalog: course.catalog,
                    //     career: course.career,
                    //     classUnit: course.classUnit,
                    //     prerequisites: course.prerequisites || 'None', // Default to 'None'
                    //     crosslisted: course.crosslisted || 'N/A',      // Default to 'N/A'
                    //   }));

                    //   dataLoaded.value = true; // Set data loaded flag
                    //   console.log('Formatted terms:', terms.value);
                    // } else {
                    //   // Handle cases where the data is not an array
                    //   console.error('Unexpected response format. Expected an array but got:', response.data);
                    // }
                } catch (error) {
                    console.error('Error fetching terms:', error);
                }
            };


            const filteredData = computed(() => {
                const result = [];
                let currentLetter = null;
                let currentItems = [];
                for (const item of terms.value) {
                    const letter = item['VEDETTE_FRANÇAISE'][0].toUpperCase();
                    if (letter !== currentLetter) {
                        if (currentLetter !== null) {
                            result.push({ letter: currentLetter, items: currentItems });
                        }
                        currentLetter = letter;
                        currentItems = [];
                    }
                    currentItems.push(item);
                }
                if (currentLetter !== null) {
                    result.push({ letter: currentLetter, items: currentItems });
                }
                return result;
            });

            const onSearchInputChange = () => {
                dataService.getRelatedTerms(searchTerm.value).then(response => {
                    terms.value = response;
                });
            };

            const scrollToSection = (letter) => {
                const element = document.getElementById(`section-${letter}`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            };

            const navigateToDetails = (term) => {
                dataService.getData2(term).then((response) => {
                    data2.value = response;
                    data1.value = false;
                    ifdata2.value = true;
                    goToDetailsPage(response);
                }).catch((error) => {
                    console.error('Error fetching data:', error.message);
                });
            };

            const goToDetailsPage = () => {
                console.log("Navigating to Details Page");
                router.push({
                    path: '/details',
                    query: { data: JSON.stringify(data2.value) },
                });
            };

            const scrollToTop = () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            };

            const handleScroll = () => {
                console.log('scrollY:', window.scrollY);
                showScrollButton.value = window.scrollY > 300;
                console.log('showScrollButton:', showScrollButton.value);
            };

            onMounted(() => {
                fetchTerms();

                window.addEventListener('scroll', handleScroll);
            });

            onUnmounted(() => {
                window.removeEventListener('scroll', handleScroll);
            });

            return {
                searchTerm,
                terms,
                dataLoaded,
                showScrollButton,
                alphabet,
                filteredData,
                onSearchInputChange,
                scrollToSection,
                navigateToDetails,
                goToDetailsPage,
                scrollToTop
            };
        }
    };
</script>

<style scoped>
    header {
        width: 100%;
        height: auto;
        margin: 0;
        background-color: #ffd700;
        color: #fff;
        box-sizing: border-box;
        text-align: center;
        padding: 20px;
    }

        header h1 {
            margin: 0;
            padding: 0;
        }

    .search-container {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .search-bar {
        display: flex;
        width: 400px;
        align-items: center;
        padding: 10px;
        border: none;
        border-radius: 24px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .search-input {
        border: none;
        outline: none;
        width: 100%;
        padding: 8px;
        border-radius: 24px;
        /* Match the border-radius of the search bar */
        flex-grow: 1;
    }

    .search-icon {
        margin-right: 10px;
        font-size: 20px;
        color: #555;
    }

    input[type="text"] {
        padding: 8px;
        width: 70%;
        box-sizing: border-box;
        margin-right: 5px;
    }

    .index-button {
        text-align: center;
        margin-bottom: 10px;
        /* Adjust spacing as needed 1*/
    }

        .index-button span {
            margin: 0 15px;
            padding: 2px;
            cursor: pointer;
        }

    .content-home {
        margin: 20px 0 0 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        background-color: #f4f4f4;
    }

    .column-headers {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        text-decoration: underline;
    }

    .column-header {
        flex: 1;
        margin: 0 50px;
        font-weight: bold;
    }

    .side-by-side-list {
        list-style-type: none;
        padding: 0;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .side-by-side-container-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .side-by-side-container {
        display: flex;
        justify-content: space-between center;
        align-items: center;
        margin-bottom: 10px;
    }

    .left-item,
    .right-item {
        flex: 1;
        margin: 0 50px;
    }

    .left-item {
        cursor: pointer;
    }

    .index-btn {
        display: inline-block;
        background-color: #912338;
        color: white;
        padding: 12px 20px;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
    }

        .index-btn:hover {
            background-color: #7f8c8d;
            color: #fff;
        }

    .rounded-button {
        display: inline-block;
        padding: 15px 20px;
        border-radius: 24px;
        background-color: #3498db;
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
    }

        .rounded-button:hover {
            background-color: #7f8c8d;
            color: #fff;
        }

    .horizontal-space {
        width: 20px;
        display: inline-block;
    }

    .separator-line {
        border-bottom: 1px solid #ccc;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 2.5rem;
    }

    .scroll-to-top-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        display: block;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 8px;
        cursor: pointer;
        opacity: 1;
        transition: opacity 1s ease-in-out;
        /* z-index: 1000; */
    }

        .scroll-to-top-button:hover {
            background-color: #0056b3;
        }

    .show {
        opacity: 1;
    }

    .index-page {
        width: 100%;
        margin: 0;
    }

    .centered-button {
        text-align: center;
        margin-top: 10px;
        /* Adjust spacing as needed */
    }

    .index-title {
        text-align: center;
    }

    .index-container {
        font-family: Arial, sans-serif;
    }

    .alphabet-navigation {
        background-color: #f8f9fa;
        padding: 10px 0;
    }

    .alphabet-letter {
        display: inline-block;
        margin: 0 15px;
        cursor: pointer;
        font-weight: bold;
        font-size: 26px;
        color: #000;
    }

        .alphabet-letter:hover {
            color: #74232f;
        }

    .index-list {
        margin-top: 20px;
    }

    .index-section {
        margin-bottom: 20px;
    }

        .index-section h3 {
            margin-top: 20px;
            margin-bottom: 10px;
        }

    .table {
        width: 100%;
        margin-bottom: 20px;
    }

        .table tbody tr td {
            padding: 8px;
            border: 1px solid #ddd;
        }
</style>