<template>
    <div class="custom-container">
        <AppHeader />

        <main class="main-content">
            <div v-if="isLoading">Loading...</div>
            <div v-else class="main-container">
                <div class="frame38">
                    <div class="frame23">
                        <div class="frame25">
                            <div class="icon-group">
                                <div class="french-icons">
                                    <div class="vocab">{{ resultDetails.VEDETTE_FRANÇAISE || 'N/A' }}</div>
                                    <button class="icon-container">
                                        <img src="@/assets/speakerIcon.svg" alt="Speaker Icon" class="icon" @click="speak(resultDetails.VEDETTE_FRANÇAISE)" />
                                    </button>
                                    <button class="icon-container" @click="copyText(resultDetails.VEDETTE_FRANÇAISE)">
                                        <img src="@/assets/copyIcon.svg" alt="Copy French Term Icon" class="icon" />
                                    </button>
                                </div>

                                <div class="english-icons">
                                    <p class="vocab-en">{{ resultDetails.VEDETTE_ANGLAISE || 'N/A' }}</p>
                                    <button class="icon-container-en" @click="copyText(resultDetails.VEDETTE_ANGLAISE)">
                                        <img src="@/assets/copyIcon.svg" alt="Copy English Term Icon" class="icon-en" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="frame24" class="frame24">
                            <div id="definition-title" class="definition-title">
                                Définition
                            </div>
                            <div id="definition-content" class="definition-content">
                                {{ resultDetails.DEFINITION || 'N/A' }}
                            </div>
                        </div>

                        <div class="frame31">
                            <div class="frame36">
                                <div class="frame29">
                                    <div class="frame26">
                                        <div class="domain">Domaine</div>
                                        <div 
                                            class="domain-value clickable" 
                                            @click="navigateToDomain(resultDetails.DOMAINE)">
                                            {{ resultDetails.DOMAINE || 'N/A' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="frame312">
                                    <div class="frame262">
                                        <div class="subdomain">Sous-domaine</div>
                                        <div class="subdomain-value">{{ resultDetails.SOUS_DOMAINE || 'N/A' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="frame32">
                            <div class="contextefrançais">Contexte français</div>
                            <div v-html="highlightedContext"></div>

                            <div class="source-contexte-heading">Source contexte français</div>
                            <div v-html="linkedSource"></div>
                        </div>

                        <div class="frame292">
                            <div class="frame265">
                                <div class="author">Rédigé par: {{ resultDetails.FICHE_CRÉÉE_PAR || 'N/A' }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Évaluation de la page Section -->
                <section class="evaluation-page mt-5 p-4 border rounded bg-light">
                    <h3 class="section-title mb-3">Évaluation de la page</h3>
                    <div class="mb-3">
                        <label class="form-label me-3">
                            <input type="radio" name="evaluation" value="oui" v-model="evaluationResponse" /> Oui
                        </label>
                        <label class="form-label">
                            <input type="radio" name="evaluation" value="non" v-model="evaluationResponse" /> Non
                        </label>
                    </div>
                    <p class="info-text">
                        Avez-vous un commentaire à nous transmettre ou un problème à signaler?<br />
                        Évitez d’inscrire des renseignements personnels. Prenez note que vous ne recevrez aucune réponse.
                    </p>
                    <textarea
                        class="form-control mt-3"
                        rows="4"
                        placeholder="Écrivez votre commentaire ici..."
                        v-model="evaluationComment"
                    ></textarea>
                </section>
            </div>
        </main>

        <AppFooter />
    </div>
</template>

<script>
    import AppHeader from './AppHeader.vue';
    import AppFooter from './AppFooter.vue';
    import { useDataService } from '../dataService.ts';

    export default {
        name: 'DynamicPage',
        components: {
            AppHeader,
            AppFooter,
        },
        data() {
            return {
                term: '',
                resultDetails: {}, // Holds the details of the term
                isLoading: true, // Track loading state
                showToast: false, // Track toast visibility
                toastMessage: '', // Message to display in the toast
                showCopyTooltip: false, // Track tooltip visibility
                evaluationResponse: null, // Radio button value ("oui" or "non")
                evaluationComment: '', // Comment text box value
            };
        },
        computed: {
            highlightedContext() {
                // Bold the term in the context
                const termFR = this.resultDetails.VEDETTE_FRANÇAISE || '';
                const context = this.resultDetails.CONTEXTE || '';
                if (termFR && context.includes(termFR)) {
                    const regex = new RegExp(`(${termFR})`, 'gi');
                    return context.replace(regex, '<b>$1</b>');
                }
                return context;
            },
            linkedSource() {
                // Turn URLs into clickable links
                const source = this.resultDetails.SOURCE_CONTEXTE_FRANÇAISE || '';
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                return source.replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
            },
        },
        mounted() {
            this.term = this.$route.query.terme;
            console.log(`Fetching term: ${this.term}`);
            this.getTermInformation(this.term);
        },
        watch: {
            resultDetails(newVal) {
                console.log('resultDetails updated:', newVal);
                if (Object.keys(newVal).length > 0) {
                    this.isLoading = false;
                }
            },
        },
        methods: {
            async getTermInformation(term) {
                try {
                    const dataService = useDataService();
                    const response = await dataService.getData2(term);
                    console.log('Fetched term details:', response);
                    this.resultDetails = { ...response };
                } catch (error) {
                    console.error(`Error fetching details for term "${term}":`, error);
                    this.isLoading = false;
                }
            },

            async speak(text) {
                try {
                    const dataService = useDataService();
                    const audioData = await dataService.getSynthesizedSpeech(text);
                    const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    const audio = new Audio(audioUrl);
                    await audio.play().catch(error => {
                        console.error('Audio play failed:', error);
                    });
                } catch (error) {
                    console.error("Error fetching audio:", error);
                }
            },

            async copyText(text) {
                if (text) {
                    try {
                        await navigator.clipboard.writeText(text);
                        this.showCopyTooltip = true;
                        setTimeout(() => {
                            this.showCopyTooltip = false;
                        }, 2000);
                    } catch (err) {
                        console.error('Failed to copy:', err);
                    }
                } else {
                    console.warn('No text to copy.');
                }
            },

            navigateToDomain(domain) {
                if (domain) {
                    const route = `/${domain
                        .normalize('NFD') // Normalize to separate accents from letters
                        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
                        .toLowerCase()
                        .replace(/ /g, '-')}`; // Replace spaces with hyphens
                    console.log(`Navigating to: ${route}`);
                    this.$router.push(route);
                } else {
                    console.warn('No valid domain provided');
                }
            },
        },

    };
</script>

<style scoped>
    .custom-container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }

    .evaluation-page {
        max-width: 940px;
        margin: 40px auto; /* Add spacing from surrounding content */
        padding: 16px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f8f9fa;
    }


    .section-title {
        font-family: "Gill Sans MT Pro Bold Condensed";
        font-size: 24px;
        color: #333;
    }

    .info-text {
        font-family: "Gill Sans MT Pro";
        font-size: 16px;
        color: #555;
        line-height: 1.5;
    }

    textarea.form-control {
        font-family: "Gill Sans MT Pro";
        font-size: 16px;
    }


    .main-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .main-container {
        width: 100%;
        max-width: 940px;
        padding: 60px 16px 200px; /* Ensure bottom padding is sufficient to prevent overlap */
        display: flex;
        flex-direction: column;
        gap: 60px;
    }


    .frame25, .frame31 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .icon-container, .icon-container-en {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        margin-right: 10px;
    }

    .icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .icon-en {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    .vocab {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: "Gill Sans MT Pro Bold Condensed";
        font-size: 40px;
        font-weight: 700;
        letter-spacing: -0.4px;
    }

    .vocab-en {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: "Gill Sans MT Pro";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: -0.4px;
    }

    .icon-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .french-icons, .english-icons {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .frame23 {
        height: 642px;
        width: 780px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .domain, .subdomain {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: "Gill Sans MT Pro";
        font-size: 20px;
        font-weight: 700;
    }

    .domain-value {
        font-family: Arial, sans-serif;
        font-size: 20px;
        text-decoration: underline;
        color: #912338;
    }

    .subdomain-value {
        font-family: Arial, sans-serif;
        font-size: 20px;
    }

    .definition-title {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: "Gill Sans MT Pro";
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .definition-content {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: Arial;
        font-size: 20px;
        line-height: 150%;
    }

    .contextefrançais {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: "Gill Sans MT Pro";
        font-size: 30px;
        font-weight: 500;
    }

    .source-contexte-heading {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: "Gill Sans MT Pro";
        font-size: 30px;
        font-weight: 500;
        margin-top: 20px; /* Space above the new heading */
        margin-bottom: 8px; /* Space below the heading */
    }

    .source-contexte {
        color: var(--Text-Body-text, #2C2C2C);
        font-family: Arial;
        font-size: 20px;
        line-height: 150%;
    }

    .copy-tooltip {
        position: fixed;
        bottom: 70px;
        right: 20px;
        padding: 8px 16px;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        font-family: Arial, sans-serif;
        font-size: 14px;
        z-index: 1000;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        animation: fadeInOut 2s ease-in-out;
    }

    @keyframes fadeInOut {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }

        10%, 90% {
            opacity: 1;
            transform: translateY(0);
        }

        100% {
            opacity: 0;
            transform: translateY(20px);
        }
    }

    .domain-value.clickable {
        color: #912338; /* Link color */
        text-decoration: underline;
        cursor: pointer;
        font-weight: 700;
    }

    .domain-value.clickable:hover {
        text-decoration: none;
    }

</style>


