<template>
  <div id="lexi-app">
    <router-view />
    <FeedbackPopup />
  </div>
</template>

<script>
import FeedbackPopup from "./components/FeedbackPopup.vue";

export default {
  name: "App", // This is where the name property is added
  components: {
    FeedbackPopup,
  },
};
</script>
