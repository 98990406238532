<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 bg-image py-5 px-5">
                <router-link to="/" class="index-btn">Accueil</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AboutUs',
};
</script>

<style scoped>
.bg-image {
    background-image: url('@/assets/aboutconcordia.jpeg');
    /* Ensure this path points to your image */
    background-size: cover;
    /* This will make the image cover the entire div */
    background-position: center;
    /* This will center the image within the div */
    height: 680px;
    /* Adjust height as necessary */
    display: flex;
    /* Flexbox properties to center the content */
    justify-content: start;
    align-items: end;
    color: white;
    /* Adjust text color for better visibility */
}

.index-btn {
    background-color: #912338;
    color: white;
    padding: 10px 20px 10px 20px;
}

.index-btn:hover {
    background-color: #7f8c8d;
    color: #fff;
}
</style>