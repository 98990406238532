const { createApp } = require('vue');
const App = require('./App.vue').default;
const router = require('./router');

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '@/assets/fonts.css';

const app = createApp(App);
app.use(router);
app.mount('#lexi-app');

