<template>
  <div class="custom-container">
    <AppHeader />

    <main class="row justify-content-center">
      <div class="main-container">
        <div class="header-row">
          <p class="result-text" lang="fr">Résultat pour "{{ term }}"</p>
        </div>

        <div class="lexi-result-buttons">
          <p class="result-count">{{ results.length }} résultats</p>
          <div class="button-group">
              <button @click="toggleView" class="view-toggle-button">
                <img src="@/assets/fourLines.svg" alt="Double Arrow Icon" class="icon" width="20" /> 
                {{ isCardView ? 'List View' : 'Card View' }}
              </button>
          </div>
        </div>

        <div v-for="result in results" :key="result.details.VEDETTE_FRANÇAISE" class="term-section" @click="navigateToDetails(result)">
          <div class="details-box" :class="{ 'list-view': !isCardView }">
            <div class="row-content">
              <div class="left-content">
                <h4>
                  <span class="frenchterm">
                    {{ result.details.VEDETTE_FRANÇAISE }}
                  </span>
                  <span class="language-box">{{ 'FR' }}</span> |
                  <span>
                    {{ result.details.VEDETTE_ANGLAISE }}
                  </span>
                  <span class="language-box">{{ 'EN' }}</span>
              </h4>
              </div>
              <div v-if="!isCardView" class="right-content">
                <span class="bold">{{ result.details.DOMAINE }}</span> | {{ result.details.SOUS_DOMAINE }}
              </div>
            </div>
            <div v-if="isCardView" class="row-2">
              <br />
              <span class="bold">{{ result.details.DOMAINE }}</span> | {{ result.details.SOUS_DOMAINE }}
              <br /><br />
              <div class="definition">{{ result.details.DEFINITION }}</div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import { useDataService } from '../dataService.ts';

export default {
  name: 'SearchPage',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      term: '',
      results: [], // Store search results
      isCardView: true, // Track view state
      isFlipped: false, // Track language state
    };
  },
  mounted() {
    this.term = this.$route.query.terme;
    this.fetchSearchResults(this.term);
  },
  watch: {
    '$route.query.terme': function(newTerm) {
      this.term = newTerm;
      this.fetchSearchResults(newTerm);
    }
  },
  methods: {
    async fetchSearchResults(term) {
      const dataService = useDataService();
      try {
        const response = await dataService.getRelatedTerms(term);
        const filteredResults = response
          .filter((result) => result.toLowerCase().startsWith(term.toLowerCase()))
          .slice(0, 10);

        const promises = filteredResults.map(async (term) => {
          const details = await this.getTermInformation(term);
          return { details };
        });

        this.results = await Promise.all(promises);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    },

    async getTermInformation(term) {
      const dataService = useDataService();
      try {
        const response = await dataService.getData2(term);
        return response;
      } catch (error) {
        console.error(`Error fetching details for term "${term}":`, error);
        return {};
      }
    },

    navigateToDetails(result) {
      const englishTerm = result.details.VEDETTE_ANGLAISE;
      this.$router.push({
        path: '/details',
        query: { terme: englishTerm },
      });
    },

    toggleView() {
      this.isCardView = !this.isCardView;
    },

    toggleLanguage() {
      this.isFlipped = !this.isFlipped;
    },
  }
};
</script>

<style scoped>
.custom-container {
  overflow-x: hidden;
}

.frenchterm {
  font-family: "Gill Sans MT Pro Bold Condensed";
}

.main-container {
  width: 940px;
  padding: 60px 16px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 10px;
}

.lexi-result-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.view-toggle-button {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.view-toggle-button:hover {
  background-color: #f0f0f0;
}

.result-text {
  font-family: 'Gill Sans MT Pro', sans-serif;
  font-size: 2rem;
}

.result-count {
  font-size: 1rem;
}

.term-section {
  width: 940px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 16px;
  border-top: 1px solid #C8C8C8;
  transition: background-color 0.2s ease;
}

.term-section:hover {
  background: rgba(240, 240, 240, 0.6);
}

.details-box {
  padding: 10px;
}

.details-box.list-view {
  display: flex;
  flex-direction: column; /* Stack left-content and right-content */
  align-items: flex-start; /* Align items to the left */
  width: 100%; /* Ensure it takes full width */
}

.row-content {
  width: 100%; /* Full width for left-content and right-content */
  display: flex;
  flex-direction: column; /* Stack content vertically */
}

.left-content, .right-content {
  width: 100%; /* Ensure both contents take full width */
  text-align: left; /* Align text to the left */
  margin-top: 5px; /* Add some spacing between sections */
}

.right-content {
  margin-top: 1px; /* Additional spacing specifically for right-content */
}


.row-2 {
  font-family: 'Gill Sans MT Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.language-box {
  width: 34px;
  height: 23px;
  padding: 4px 8px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.definition{
  font-family: 'Arial';
}

@media (max-width: 992px) {
  .main-container {
    width: 768px;
  }
  .term-section{
    width: 768px;
  }
}

@media (max-width: 768px) {
  .main-container {
    width: 100%;
  }
  .term-section{
    width: 100%;
  }
}
</style>





