<template>
    <!-- Header Section -->
    <!-- <header class="row justify-content-center">
    </header> -->
  
    <!-- Off-canvas Navigation -->
    <!-- <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <router-link to="/index" class="nav-link nav-text" lang="fr">Index général</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link nav-text" lang="fr">Notre équipe</router-link>
          </li>
        </ul>
      </div>
    </div> -->
  
    <!-- Search Bar Section -->
    <div class="search-div row align-items-center justify-content-center position-relative bg-light" ref="searchWrapper">
      <div class="head d-flex justify-content-center align-items-center">
          <router-link to="/">
            <img src="@/assets/Logo.svg" alt="LEXICONCORDIA" class="img-fluid logo-lexiconcordia py-5" />
          </router-link>
        </div>
        <div class="container-fluid">
          <div class="row justify-content-center text-center">
            <div class="col-12 width940">
              <p class="description-text mb-5 mb-lg-3" lang="fr">
                LexiConcordia est un outil de recherche terminologique pour utiliser les bons mots et la bonne orthographe.
              </p>
            </div>
          </div>
        </div>
      <div class="width768 search position-absolute" style="bottom: -40px; width: 768px; max-width: calc(100vw - 24px);">
        <!-- Search Input Field -->
        <div class="form-group">
          <form @submit.prevent="onSubmit">
            <div class="position-relative">
              <input
                type="text"
                class="form-control search-input py-3 rounded-2 shadow"
                placeholder="Rechercher un terme..."
                v-model="textInput"
                @input="onSearchInputChange"
                @keydown.esc="clearSearch"
                required
              />
              <img src="./../assets/Vector.svg" alt="Search Icon" class="search-icon" @click="onSubmit" />
              <img v-if="textInput != ''" src="./../assets/Close.svg" alt="Close Icon" class="close-icon" @click="clearSearch" />
            </div>
          </form>
        </div>
      <!-- Dropdown for Suggested Words -->
      <div v-if="textInput !== ''" class="suggestion-dropdown">
        <ul class="dropdn">
          <li
            v-for="(word, index) in relatedWords.slice(0, 10)"
            :key="word"
            :class="{ highlighted: index === highlightedIndex }"
            ref="dropdownItems"
            @click="onWordClick(word)"
          >
            <img src="./../assets/Vector.svg" alt="Search Icon" class="search-icon-list" />
            <span v-html="highlightMatchedText(word)"></span>
          </li>

          <!-- Conditional message based on isSearching -->
          <li v-if="relatedWords.length === 0" class="no-results">
            <span v-if="isSearching">À la recherche de termes...</span>
            <span v-else>Pas de résultat</span>
          </li>
        </ul>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  const { ref, onMounted, onBeforeUnmount } = require('vue');
  const { useRouter } = require('vue-router');
  const { useDataService } = require('../dataService.ts');
  
  export default {
    name: 'AppHeader',
    setup() {
      const title = 'lexic';
      const data = ref(null);
      const data2 = ref(null);
      const data1 = ref(false);
      const ifdata2 = ref(false);
      const textInput = ref('');
      const relatedWords = ref([]);
      const searchWrapper = ref(null);
      const highlightedIndex = ref(-1); // Track the highlighted index
      const dropdownItems = ref([]); // Track dropdown items
      const isSearching = ref(true); // Flag to track if a search is in progress
  
      const dataService = useDataService();
      const router = useRouter();
  
      const onIndex = () => {
        console.log("Navigating to Index Page...");
        router.push('/index');
      };
  
      const onAbout = () => {
        console.log("Navigating to About Page...");
        router.push('/about');
      };
  
      const onSearchInputChange = () => {
        console.log(isSearching);
        if (textInput.value.trim() !== '') {
          isSearching.value = true; // Start search
          console.log(isSearching);
          dataService.getRelatedTerms(textInput.value)
            .then((response) => {
              const normalizedInput = textInput.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
              relatedWords.value = response.filter((word) =>
                word.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().startsWith(normalizedInput.toLowerCase())
              );
              highlightedIndex.value = -1; // Reset index for new results
            })
            .catch((error) => {
              console.error('Error fetching related terms:', error.message);
            })
            .finally(() => {
              isSearching.value = false; // Mark search as complete
            });
        } else {
          relatedWords.value = [];
          //isSearching.value = false; // No search if input is empty
        }
      };
  
  
  
      const onWordClick = (word) => {
        textInput.value = word;
        onSubmit();  // This will now trigger the routing to SearchPage.vue
      };
  
  
      const onSubmit = () => {
        if (textInput.value) {
          router.push({
            path: '/recherche',
            query: { terme: textInput.value },
          });
          clearSearch();
        }
      };
  
      const highlightMatchedText = (word) => {
        const searchText = textInput.value.trim().toLowerCase();
        const wordLowerCase = word.toLowerCase();
  
        if (wordLowerCase.startsWith(searchText)) {
          const matchedText = word.substring(0, searchText.length);
          const remainingText = word.substring(searchText.length);
  
          return `
            <span style="font-weight: 700;">${matchedText}</span><span style="font-weight: 500;">${remainingText}</span>
          `;
        }
        return `<span style="font-weight: 500;">${word}</span>`;
      };
  
      const goToDetailsPage = () => {
        router.push({
          path: '/details',
          query: { data: JSON.stringify(data2.value) },
        });
      };
  
      const clearSearch = () => {
        textInput.value = ''; 
        relatedWords.value = [];
        highlightedIndex.value = -1; // Reset index when clearing the search
      };
  
      const goToSearchPage = () => {
        router.push('/recherche');
      };
  
      const handleKeyDown = (event) => {
        const maxIndex = Math.min(relatedWords.value.length, 10) - 1;
  
        switch (event.key) {
          case 'ArrowDown':
            if (highlightedIndex.value < maxIndex) {
              highlightedIndex.value += 1;
            }
            break;
  
          case 'ArrowUp':
            if (highlightedIndex.value > 0) {
              highlightedIndex.value -= 1;
            }
            break;
  
          case 'Enter':
            if (highlightedIndex.value >= 0 && highlightedIndex.value <= maxIndex) {
              onWordClick(relatedWords.value[highlightedIndex.value]);
            }
            break;
  
          default:
            break;
        }
  
        // Auto-scroll to ensure the highlighted item is visible
        const dropdownItemsList = searchWrapper.value.querySelectorAll('li');
        const dropdownItem = dropdownItemsList[highlightedIndex.value];
  
        if (dropdownItem) {
          dropdownItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
      };
  
      // Close dropdown when clicked outside the specific search input area
      const handleClickOutside = (event) => {
        const searchContainer = document.querySelector('.form-group');
        if (searchContainer && !searchContainer.contains(event.target)) {
          clearSearch();
        }
      };
  
      // Add and remove event listeners
      onMounted(() => {
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
      });
  
      onBeforeUnmount(() => {
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('keydown', handleKeyDown);
      });
  
      return {
        title,
        data,
        data2,
        data1,
        ifdata2,
        onIndex,
        onAbout,
        textInput,
        relatedWords,
        onSearchInputChange,
        onWordClick,
        onSubmit,
        goToDetailsPage,
        clearSearch,
        searchWrapper,
        goToSearchPage,
        highlightedIndex,
        dropdownItems,
        highlightMatchedText,
        isSearching
      };
    },
  };
  
  </script>
  
  
  <style scoped>
  header {
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .head {
    width: 940px;
  }
  
  .nav-text {
    color: #912338;
    font-size: 0.95rem;
    font-weight: 700;
    line-height: 1.5rem;
    font-family: 'Gill Sans MT Pro';
    text-align: left;
  }
  
  .nav-text:hover {
    text-decoration: underline;
  }
  
  .nav-icon{
    display: none;
  }

  .logo-lexiconcordia {
    width: 20vw;
    min-width: 250px;
  }

  .description-text {
    font-size: 28px;
    letter-spacing: -0.02em;
    text-wrap: balance;
    font-family: 'Gill Sans MT Pro Book';

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .search-div {
    /* background-color: #f0f0f0; */
    padding: 80px 0;
  }
  
  .search {
    width: 768px;
    padding: 16px 0;
    position: relative;
  }
  
  .form-control{
    border: none;
  }
  
  .search-input {
    padding: 16px;
    border-radius: 4px;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .highlighted {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .search-icon-list {
    top: 50%;
    width: 20px;
    height: 20px;
  }
  
  .offcanvas.offcanvas-end{
    width: 220px;
  }
  
  .close-icon {
    position: absolute;
    top: 50%;
    right: 3.25rem;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  
  .suggestion-dropdown {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1;
    max-height: 200px; /* Adjust this for exactly 5 list elements if needed */
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow effect */
    border-radius: 0px 0px 4px 4px;
  }
  
  /* Ensure consistent height for exactly five items */
  .suggestion-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .suggestion-dropdown li {
    padding: 12px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    color: var(--grey-700, #000);
    font-family: "Gill Sans MT Pro Book";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .suggestion-dropdown li:hover {
    background-color: #f0f0f0;
  }
  
  /* Adjust height if there are at least 5 items */
  .suggestion-dropdown ul:has(li:nth-child(5)) {
    max-height: calc(40px * 5); /* Limit to 5 items */
    overflow-y: auto;
  }

  /* Ensure no color change on hover and apply consistent gray styling */
  .suggestion-dropdown .no-results {
      padding: 12px;
      color: gray !important; /* Apply gray color consistently */
      font-family: "Gill Sans MT Pro Book";
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      background-color: white !important; /* Ensure background remains white */
      cursor: default;
  }

  /* Prevent hover effect on .no-results */
  .suggestion-dropdown .no-results:hover {
      background-color: white !important; /* Stay white on hover */
      color: gray !important; /* Stay gray on hover */
      cursor: default;
  }
    
  .search-icon-list {
    filter: grayscale(100%);
    opacity: 0.5; /* Optional: adjust opacity to fine-tune the gray tone */
    width: 16px;
    margin-right: 10px;
  }
  
  /* Responsive styling */
  @media (max-width: 992px) {
    .head {
      width: 736px;
    }
    .search-div {
      padding: 40px 16px;
    }
    .search {
      width: 736px;
    }
  }
  
  @media (max-width: 768px) {
    header {
      padding: 8px 16px;
    }
    .nav {
      display: none;
    }
    .search-div {
      padding: 20px 24px;
    }
  }
  </style>
